import "bootstrap";
import './smooth_scroll';
import './scroll_link_highlight';
import './email_form';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

window.addEventListener('load', function() {
    $(document).scroll(function () {
        var $nav = $(".nav-wrapper.fixed-top");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
    });

    $('.modal--navbar .nav-link').click(function(e) {
        $('#navModal').modal('hide')
    });
})

