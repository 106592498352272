window.addEventListener('load', function() {
    $("#email_form").submit((e) => {
        e.preventDefault();
        let form_values = $("#email_form").serializeArray();

        var settings = {
            "async": true,
            "crossDomain": true,
            "url": `/send_form_email.php?firstName=${form_values[0]["value"]}&lastName=${form_values[1]["value"]}&email=${form_values[2]["value"]}&phoneNumber=${form_values[3]["value"]}&comment=${form_values[4]["value"]}`,
            "method": "POST",
            "headers": {
                "Accept": "*/*",
                "Cache-Control": "no-cache",
                "cache-control": "no-cache"
            }
        }
        $.ajax(settings).done();
        $("#email_form").hide(500, function(){
            $("#email-message").show(500);
        });
    })
})