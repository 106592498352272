//  Source: https://stackoverflow.com/questions/43500315/highlighting-links-based-on-scroll-position
window.addEventListener('load', function() {
    $(window).scroll(function() {
        var scrollPos = $(window).scrollTop() + 50;
    
        var page1Top = $("#hero-section").offset().top;
        var page2Top = $("#work-section").offset().top;
        var page3Top = $("#service-section").offset().top;
        var page4Top = $("#contact-us").offset().top;
    
        if (scrollPos >= page1Top && scrollPos < page2Top) {
            $(".nav-link--hero").addClass("active");
            $(".nav-link--work").removeClass("active");
            $(".nav-link--service").removeClass("active");
            $(".nav-link--contact").removeClass("active");
        } 
        else {
            $(".nav-link--hero").removeClass("active");
        }
    
        if (scrollPos >= page2Top && scrollPos < page3Top) {
            $(".nav-link--work").addClass("active");
            $(".nav-link--hero").removeClass("active");
            $(".nav-link--service").removeClass("active");
            $(".nav-link--contact").removeClass("active");
        } 
        else {
            $(".nav-link--work").removeClass("active");
        }

        if (scrollPos >= page3Top && scrollPos < page4Top) {
            $(".nav-link--service").addClass("active");
            $(".nav-link--hero").removeClass("active");
            $(".nav-link--work").removeClass("active");
            $(".nav-link--contact").removeClass("active");
        } 
        else {
            $(".nav-link--service").removeClass("active");
        }

        if (scrollPos >= page4Top) {
            $(".nav-link--contact").addClass("active");
            $(".nav-link--hero").removeClass("active");
            $(".nav-link--work").removeClass("active");
            $(".nav-link--service").removeClass("active");
        } 
        else {
            $(".nav-link--contact").removeClass("active");
        }
    });
})
